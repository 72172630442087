
.main-error-page {
    min-height: 600px;
    margin: 40px auto;
    width: auto;
    max-width: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error-title {
    max-width: 529px;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b62;
    margin-bottom: 16px;
}

.error-subtitle {
    max-width: 568px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    /* color: #4b4b62; */
    margin-bottom: 24px;
}

.pao-atras {
    animation: leftright 1s alternate infinite;
    transform-origin: center;
}

.pao-frente {
    animation: leftright 1s 0.3s alternate infinite;
    transform-origin: center;
}

.olho-esq {
    animation: sad 2s alternate infinite;
    transform-origin: center;
}

.olho-dir {
    animation: sad 2s alternate infinite;
    transform-origin: center;
}

.boca {
    animation: sad 2s alternate infinite;
    transform-origin: center;
}

.raios {
    -webkit-animation: flicker-4 4s linear infinite both;
    animation: flicker-4 4s linear infinite both;
}

.tomada {
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
}

.fio-500 {
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
}

.fio {
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
}

@keyframes scales {
    from {
        transform: scale(0.98);
    }

    to {
        transform: scale(1);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 14:58:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {

    0%,
    100% {
        opacity: 1;
    }

    31.98% {
        opacity: 1;
    }

    32% {
        opacity: 0;
    }

    32.8% {
        opacity: 0;
    }

    32.82% {
        opacity: 1;
    }

    34.98% {
        opacity: 1;
    }

    35% {
        opacity: 0;
    }

    35.7% {
        opacity: 0;
    }

    35.72% {
        opacity: 1;
    }

    36.98% {
        opacity: 1;
    }

    37% {
        opacity: 0;
    }

    37.6% {
        opacity: 0;
    }

    37.62% {
        opacity: 1;
    }

    67.98% {
        opacity: 1;
    }

    68% {
        opacity: 0;
    }

    68.4% {
        opacity: 0;
    }

    68.42% {
        opacity: 1;
    }

    95.98% {
        opacity: 1;
    }

    96% {
        opacity: 0;
    }

    96.7% {
        opacity: 0;
    }

    96.72% {
        opacity: 1;
    }

    98.98% {
        opacity: 1;
    }

    99% {
        opacity: 0;
    }

    99.6% {
        opacity: 0;
    }

    99.62% {
        opacity: 1;
    }
}

@keyframes flicker-4 {

    0%,
    100% {
        opacity: 1;
    }

    31.98% {
        opacity: 1;
    }

    32% {
        opacity: 0;
    }

    32.8% {
        opacity: 0;
    }

    32.82% {
        opacity: 1;
    }

    34.98% {
        opacity: 1;
    }

    35% {
        opacity: 0;
    }

    35.7% {
        opacity: 0;
    }

    35.72% {
        opacity: 1;
    }

    36.98% {
        opacity: 1;
    }

    37% {
        opacity: 0;
    }

    37.6% {
        opacity: 0;
    }

    37.62% {
        opacity: 1;
    }

    67.98% {
        opacity: 1;
    }

    68% {
        opacity: 0;
    }

    68.4% {
        opacity: 0;
    }

    68.42% {
        opacity: 1;
    }

    95.98% {
        opacity: 1;
    }

    96% {
        opacity: 0;
    }

    96.7% {
        opacity: 0;
    }

    96.72% {
        opacity: 1;
    }

    98.98% {
        opacity: 1;
    }

    99% {
        opacity: 0;
    }

    99.6% {
        opacity: 0;
    }

    99.62% {
        opacity: 1;
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}



/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:42:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {

    0%,
    100% {
        -webkit-transform: translateY(0) rotate(0);
        transform: translateY(0) rotate(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateY(-30px) rotate(6deg);
        transform: translateY(-30px) rotate(6deg);
    }

    30% {
        -webkit-transform: translateY(15px) rotate(-6deg);
        transform: translateY(15px) rotate(-6deg);
    }

    45% {
        -webkit-transform: translateY(-15px) rotate(3.6deg);
        transform: translateY(-15px) rotate(3.6deg);
    }

    60% {
        -webkit-transform: translateY(9px) rotate(-2.4deg);
        transform: translateY(9px) rotate(-2.4deg);
    }

    75% {
        -webkit-transform: translateY(-6px) rotate(1.2deg);
        transform: translateY(-6px) rotate(1.2deg);
    }
}


@keyframes sad {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }

    100% {
        transform: rotateX(10deg) rotateY(5deg);
    }
}

@keyframes leftright {

    0% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(-15deg)
    }
}